import React, { Fragment, useState } from "react";
import { Col, Form, Row } from 'reactstrap';
import { useForm } from 'react-hook-form';
import { toast } from "react-toastify";

import { ibService } from 'Services';
import CommonModal from "Components/Common/Data/Ui-kits/Modals/common/modal";
import { FInput } from 'CommonElements/Form';
import { Btn, H3, H4 } from "AbstractElements";

const MakeIb = ({ customerId, loadData }) => {
    const [modal, setModal] = useState(false);
    const [loading, setLoading] = useState(false);

    const toggle = () => {
        setModal(!modal)

    };
    const { register, handleSubmit, formState: { errors }, reset } = useForm();


    const modalSubmit = (ibType) => {
        setLoading(true);

        ibService.makeIb(customerId, ibType) // Pass ibType as an argument
            .then(res => {
                setLoading(false);
                toggle();
                reset();
                toast.success(`${ibType} approved successfully`);
                window.location.reload();
            })
            .catch(err => {
                setLoading(false);
                toast.error(`Failed to approve ${ibType}`);
            });
    };

    return (
        <Fragment>
            <Btn attrBtn={{ disabled: loading, onClick: toggle, color: 'primary' }} >{'Approve IB'}</Btn>

            <CommonModal
                isOpen={modal}
                title={'Approve IB'}
                toggler={toggle}
                formSubmit={() => { }}
                hideFooter={true}
            >
                <Row>
                    <Col md='12 mb-3' >
                        <H4>Are you sure you want to approve?</H4>
                    </Col>
                </Row>
                <Row>
                    <Col md={8} className="d-flex flex-row-reverse">
                        <Btn
                            disabled={loading}
                            attrBtn={{ color: 'primary', onClick: () => modalSubmit('IB') }} // Pass "IB" for the first button
                        >
                            {'Approve IB'}
                        </Btn>
                    </Col>
                    <Col md={4} className="d-flex flex-row-reverse">
                        <Btn
                            disabled={loading}
                            attrBtn={{ color: 'primary', onClick: () => modalSubmit('CPA') }} // Pass "CPA" for the second button
                        >
                            {'Approve CPA'}
                        </Btn>
                    </Col>
                </Row>
            </CommonModal>
        </Fragment>
    );
};

export default MakeIb;