import React, { Fragment, useEffect, useState } from 'react';
import {
  Col,
  Row,
  Table,
  Card, Form,
  CardHeader,
  CardFooter,
  CardBody,
} from 'reactstrap';
import { useSelector } from 'react-redux';
import { ibService, clientService } from 'Services';
import TableLoader from 'CommonElements/Table/TableLoader';
import TableNodata from 'CommonElements/Table/TableNodata';
import AddAgreement from './AddAgreement';
import DeleteAgreement from './DeletAgreement';
import UpdateAgreement from './EditAgreementByID';
import { getMenuPerm } from 'utils';
import { useForm } from 'react-hook-form';
import MakeIb from './MakeIb';
import RejectIb from './RejectIb';

import { FInput, FSelect } from 'CommonElements/Form';
import SweetAlert from 'sweetalert2';
import { toast } from 'react-toastify';
import ViewAgreement from 'Components/Common/Component/ViewAgreement';
import GroupSetting from './GroupSetting';
import { Btn } from 'AbstractElements';

const Agreements = (props) => {
  const {
    customerId = '',
    activeTab,
    tabNumber,
    data = {}
  } = props;
  const [rec, setRec] = useState({});
  const [deal, setDeal] = useState('');
  const [submitState, setSubmitState] = useState({
    loading: false,
  });
  const permissions = useSelector(
    (state) =>
      (state.login &&
        state.login.profile &&
        state.login.profile.Role &&
        state.login.profile.Role.permissions) ||
      {}
  );
  const userProfile = data;

  useEffect(() => {
    if (activeTab === tabNumber) {
      loadData();
    }
  }, [activeTab, tabNumber]);

  const loadData = () => {
    ibService
      .getAgreements(customerId)
      .then((res) => {
        console.log("Result is ", res);
        setRec({
          ...res,
          loading: false,
        });
      })
      .catch((err) => { });
  };
  const { register, handleSubmit, formState: { errors }, setValue, getValues, control } = useForm();
  const handleFullName = (e) => {
    setDeal(e.target.value);
    setValue('deal', e.target.value);
  };

  const onSubmit = (data) => {
    console.log('data: ', data);
    if (data !== '') {
      clientService.updateClient(customerId, data).then(res => {
        setSubmitState({
          loading: false,
          status: 'Profile updated sucessfully'
        })
        SweetAlert.fire({ title: 'Success', text: 'Profile updated sucessfully!', icon: 'success' });
      }).catch(err => {
        setSubmitState({
          loading: false,
          status: 'Error in updating Profile'
        })
        SweetAlert.fire({ title: 'Failed', text: err.response.data.message, icon: 'error' });
      });
      clientService.searchCustomers({ parentId: customerId, page: 1, limit: 100 })
        .then((res) => {
          console.log('res commision: ', res);
          if (res.data && res.data.length > 0) {
            return Promise.all(
              res.data.map(({ id }) => clientService.updateClient(id, data))
            );
          }
        })
        .then((results) => {
          if (results) {
            toast.success('IB Clients Update!');
          } else {
            toast.error('No Record Found!')
          }
        })
        .catch((err) => {
          toast.error('Failed to Update IB Clients!')
          console.log('Error:', err)
        });

    } else {
      errors.showMessage();
    }
  };

  return (
    <Fragment>
      <Card>
        <CardHeader className='p-3'>
          <Row>
            <Col md={4} className='d-flex'>
              Commission Structure
            </Col>
            <Col md={8} className='d-flex'>
              <div
                style={{ display: 'block', textAlign: 'right', width: '100%' }}
              >
                {(data?.ibStatus === null || data?.ibStatus === 'pending') && (
                  <>
                    {getMenuPerm(permissions, 'ibs:create') === 1 && (
                      <React.Fragment>
                        <MakeIb customerId={customerId} loadData={loadData} />
                        <RejectIb customerId={customerId} loadData={loadData} />
                      </React.Fragment>
                    )}
                  </>
                )}
                {/* Need to remove after work 
                <MakeIb customerId={customerId} loadData={loadData} /> &nbsp;*/}

                {(data?.ibStatus === 'approved' && data?.ibType != 'CPA') &&
                  !rec.loading &&
                  rec?.data?.length === 0 && (
                    <>
                      {getMenuPerm(permissions, 'ibs:create') === 1 && (
                        <AddAgreement
                          customerId={customerId}
                          loadData={loadData}
                        />
                      )}
                    </>
                  )}
                {/* Need to remove after work 
                  <AddAgreement
                      customerId={customerId}
                      loadData={loadData}
                    />*/}
                {!rec.loading &&
                  rec?.data?.length > 0 && (
                    <>
                      {getMenuPerm(permissions, 'ibs:create') === 1 && (
                        <AddAgreement
                          customerId={customerId}
                          loadData={loadData}
                        />
                      )}
                      
                      &nbsp;&nbsp;&nbsp;
                      {/* {getMenuPerm(permissions, 'ibs:delete') === 1 && (
                        <DeleteAgreement
                          customerId={customerId}
                          loadData={loadData}
                          agreementId={rec?.data[0]?.id}
                        />
                      )} */}
                    </>
                  )}
              </div>
            </Col>
          </Row>
        </CardHeader>

        <CardBody>
          {rec.loading && <TableLoader />}
          {!rec.loading && rec.data && rec.data.length === 0 && (
            <TableNodata title='Commission Structure' />
          )}
          {!rec.loading && rec.data && rec.data.length > 0 && (
            <Row>
              <ViewAgreement agreement={rec.data} userProfile={userProfile} loadData={loadData} customerId = {customerId} permissions={permissions} data={rec.data}/>

              <Col md={12}>
                <Row>
                  <CardBody>
                    <Form className="needs-validation" noValidate="" onSubmit={handleSubmit(onSubmit)}>
                      <Row>
                        <Col md={6}>
                          <FSelect
                            control={control}
                            errors={errors}
                            label='IB Type'
                            name='ibType'
                            register={register}
                            //placeholder= 'Enter Lead Status'
                            options={[
                              { name: 'NTP', value: 'NTP' },
                              { name: 'Rebate', value: 'Rebate' },
                            ]}
                            validation={{ required: true }}
                            value={data.callStatus}
                            setValue={setValue}
                          />
                        </Col>
                        <Col md={6} >
                          <FInput
                            errors={errors}
                            label='Deal'
                            name='deal'
                            value={deal}
                            handleOnChange={handleFullName}
                            register={register}
                            //placeholder= 'Enter First Name'
                            validation={{ required: true }}
                          />
                        </Col>
                      </Row>
                      <Btn disabled={submitState.loading} attrBtn={{ color: 'primary' }} >{'Update'}</Btn>
                    </Form>
                  </CardBody>
                </Row>
              </Col>
              <Col sm={12}>
                {getMenuPerm(permissions, 'ibs:update') === 1 && (
                  <GroupSetting
                    customerId={customerId}
                    activeTab={activeTab}
                    tabNumber={tabNumber}
                  />
                )}
              </Col>
            </Row>
          )}
        </CardBody>
      </Card>
    </Fragment>
  );
};
export default Agreements;