import api from './api';

export const swapGroup = () => {
    return api.get('/v1/crm/SwapGroup/SwapGroup');
};

export const createSwapGroup = (data) => {
  return api.post('/v1/crm/SwapGroup/SwapGroupCreate', data);
};

export const deleteSwapGroup = (id) => {
  return api.delete(`/v1/crm/SwapGroup/SwapGroup/${id}`);
};
