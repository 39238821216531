import React, { Fragment, useEffect, useState } from "react";
import { Col, Form, Row } from 'reactstrap';
import { useForm } from 'react-hook-form';
import { toast } from "react-toastify";
import { Label } from "reactstrap";

import { ibService, accountTypeService } from 'Services';
import CommonModal from "Components/Common/Data/Ui-kits/Modals/common/modal";
import { FInput } from 'CommonElements/Form';
import { Btn, H6 } from "AbstractElements";

const AddAgreement = ({ customerId,  loadData  }) => {
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [hierarchy, setHierarchy] = useState([]);
  const [liveAcTypes, setLiveAcTypes] = useState([]);

  const toggle = () => {
    setModal(!modal)
  };
  const { register, handleSubmit, formState: { errors }, reset, setValue } = useForm();
  
  useEffect(()=>{
    ibService.getHierarchy(customerId).then(res => {
        setHierarchy(res);
    }).catch(err => {
    })
    accountTypeService.getAccountTypes({type: 'LIVE'}).then(res => {
        setLiveAcTypes(res?.data || [])
    }).catch(err => {
    })
  },[])

  useEffect(()=>{
    hierarchy.forEach((member, index) => {
        setValue(`members.${index}.memberId`, member.id)
        console.log(`members.${index}.memberId`, member.id)
        liveAcTypes.forEach((acType, acIndex) => {
            setValue(`members.${index}.AccountTypeValues.${acIndex}.accountTypeId`, acType.id);
            (acIndex !== 10 || acIndex !== 11 ) && setValue(`members.${index}.AccountTypeValues.${acIndex}.rebate`, 0);
        })
    })
  },[hierarchy, liveAcTypes]);


  const modalSubmit = formValues => {
    if (formValues !== '') {
        setLoading(true);
        console.log('Modal submit ',formValues);
        const params = {...formValues, ibId: customerId};
        ibService.addAgreement(params).then(res => {
            setLoading(false);
            toggle();
            reset();
            toast.success('Agreement added, successfully');
            loadData();
        }).catch(err => {
            setLoading(false);
            console.log('error is ',err);
            toast.error('Failed to add Agreement');
        });
    } else {
        console.log("errror is here");
        errors.showMessages();
    }
};
  return (
    <Fragment>
        <Btn attrBtn={{ disabled:loading, onClick:toggle, color: 'primary' }} >{'Add Agreement'}</Btn>

        <CommonModal
            isOpen={modal}
            title={'Add Agreement'}
            toggler={toggle}
            formSubmit={()=>{}}
            hideFooter={true}
        >
            <Form  className="needs-validation" noValidate="" 
                onSubmit={handleSubmit(modalSubmit)}
            >
                    <Row>  
                        <Col md='12 mb-3' >
                            <FInput
                                errors={errors}
                                label = 'Title'
                                name = 'title'
                                register = {register}
                                placeholder= 'Enter title for Agreement'
                                validation={{required: true}}
                            />
                        </Col>
                        {hierarchy?.map((member, index) => {
                            return (<>
                                <H6 key={index} attrH6={{className: "mt-4"}}>{member.firstName}{' '}{member.lastName}</H6>
                                <FInput
                                    name = {`members.${index}.memberId`}
                                    register = {register}
                                    validation={{required: true}}
                                    type="hidden"
                                />
                                {liveAcTypes.map((accType, acIndex) => {
                                    return (
                                        <>
                                            <FInput
                                                key={acIndex}
                                                name={`members.${index}.AccountTypeValues.${acIndex}.accountTypeId`}
                                                register={register}
                                                validation={{required: true}}
                                                type="hidden"
                                            />
                                            {(accType.title === 'Standard' || accType.title === 'Standard MT4') ? (<>
                                                    <Col md='6 mt-4'>
                                                        {`Rebate for ${accType.title}`}
                                                    </Col>
                                                    <Col md='6'>
                                                        <FInput
                                                            name={`members.${index}.AccountTypeValues.${acIndex}.rebate`}
                                                            register={register}
                                                            // validation={{required: true}}
                                                            placeholder="Enter rebate value"
                                                        />
                                                    </Col>
                                                    </>
                                            ):<>
                                            <Col style={{display: 'none'}}>
                                                {`Rebate for ${accType.title}`}
                                            </Col>
                                            <Col style={{display: 'none'}}>
                                                <input
                                                    name={`members.${index}.AccountTypeValues.${acIndex}.rebate`}
                                                    register={register}
                                                    // validation={{required: true}}
                                                    defaultValue={0}
                                                    placeholder="Enter rebate value"
                                                />
                                            </Col>
                                            </>
                                            }
                                        </>
                                    )
                                })}
                            </>)
                        })}
                        <Col md={12} className="d-flex flex-row-reverse mt-4">
                            <Btn disabled={loading}  attrBtn={{ color: 'primary', type: 'submit' }} >{'Add Agreement'}</Btn>
                        </Col>
                        
                    </Row>

                    {/* <Btn disabled={submitState.loading}  attrBtn={{ color: 'primary' }} >{'Update'}</Btn> */}
                </Form>
        </CommonModal>
    </Fragment>
  );
};

export default AddAgreement;