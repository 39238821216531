import React, { Fragment, useState } from "react";
import { Col, Form, Row } from 'reactstrap';
import { useForm } from 'react-hook-form';
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

import { activitiesService, bankAccountsService } from 'Services';
import CommonModal from "Components/Common/Data/Ui-kits/Modals/common/modal";
import { FInput } from 'CommonElements/Form';
import { Btn } from "AbstractElements";

const AddBankAccount = ({ customerId, loadData }) => {
    const [modal, setModal] = useState(false);
    const [loading, setLoading] = useState(false);

    const toggle = () => {
        setModal(!modal)

    };

    const userPermissions = useSelector(
        (state) => (state.login && state.login.profile) || {}
    );

    const { register, handleSubmit, formState: { errors }, reset } = useForm();


    const modalSubmit = formValues => {
        if (formValues !== '') {
            setLoading(true);
            const params = { ...formValues, customerId };
            bankAccountsService.addBankAccount(params).then(res => {
                setLoading(false);
                toggle();
                reset();
                toast.success('Bank account added, successfully');

                const logData = {
                    type: `Bank account Added`,
                    userId: userPermissions.id,
                    createdAt: Date.now(),
                    customerId: customerId,
                    note: res
                };
    
                activitiesService.postActivity(logData);

                loadData();
            }).catch(err => {
                setLoading(false);
                toast.error('Failed to add Bank Account');
            });
        } else {
            errors.showMessages();
        }
    };
    return (
        <Fragment>
            {/* <Button disabled={loading} onClick={toggle}  color={'primary'} >{'Add Bank Account'}</Button> */}
            <Btn attrBtn={{ disabled: loading, onClick: toggle, color: 'primary' }} >{'Add Bank Account'}</Btn>

            <CommonModal
                isOpen={modal}
                title={'Add Bank Account'}
                toggler={toggle}
                formSubmit={() => { }}
                hideFooter={true}
            >
                <Form className="needs-validation" noValidate=""
                    onSubmit={handleSubmit(modalSubmit)}
                >
                    <Row>
                        <Col md='6 mb-3' >
                            <FInput
                                errors={errors}
                                label='Account Holder Name'
                                name='accountHolderName'
                                register={register}
                                placeholder='Enter Account Holder Name'
                                validation={{ required: true }}
                            />
                        </Col>
                        <Col md='6 mb-3' >
                            <FInput
                                errors={errors}
                                label='Bank Name'
                                name='bankName'
                                register={register}
                                placeholder='Enter bankName'
                                validation={{ required: true }}
                            />
                        </Col>
                        <Col md='6 mb-3' >
                            <FInput
                                errors={errors}
                                label='Account Number'
                                name='accountNumber'
                                register={register}
                                placeholder='Enter Account Number'
                                validation={{ required: true }}
                            />
                        </Col>
                        <Col md='6 mb-3' >
                            <FInput
                                errors={errors}
                                label='Swift Code'
                                name='swiftCode'
                                register={register}
                                placeholder='Enter Swift Code'
                                validation={{ required: true }}
                            />
                        </Col>
                        <Col md='6 mb-3' >
                            <FInput
                                errors={errors}
                                label='Bank Address'
                                name='bankAddress'
                                register={register}
                                placeholder='Enter Bank Address'
                                validation={{ required: false }}
                            />
                        </Col>
                        <Col md='6 mb-3' >
                            <FInput
                                errors={errors}
                                label='Address'
                                name='address'
                                register={register}
                                placeholder='Enter Address'
                                validation={{ required: false }}
                            />
                        </Col>
                        <Col md='6 mb-3' >
                            <FInput
                                errors={errors}
                                label='Currency'
                                name='currency'
                                register={register}
                                placeholder='Enter Account Currency'
                                validation={{ required: false }}
                            />
                        </Col>
                        <Col md='6 mb-3' >
                            <FInput
                                errors={errors}
                                label='IBAN'
                                name='iban'
                                register={register}
                                placeholder='Enter IBAN Number'
                                validation={{ required: true }}
                            />
                        </Col>
                        <Col md={12} className="d-flex flex-row-reverse">
                            <Btn disabled={loading} attrBtn={{ color: 'primary' }} >{'Update'}</Btn>
                        </Col>

                    </Row>

                    {/* <Btn disabled={submitState.loading}  attrBtn={{ color: 'primary' }} >{'Update'}</Btn> */}
                </Form>
            </CommonModal>
        </Fragment>
    );
};

export default AddBankAccount;
