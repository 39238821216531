import React, { useEffect, useState } from 'react';
import { Card, CardBody, Row, Col, Media } from 'reactstrap';
import Accounts from '../../../assets/images/avtar/accounts.svg';
import Deposit from '../../../assets/images/avtar/deposits.svg';
import { clientService } from "Services";
import Filter from '../../Common/Component/filter';


const Index = () => {
    const [rec, setRec] = useState({});
    useEffect(() => {
        loadData();
    }, []);

    // Function to separate page and limit, and construct body params
    const getParamsAndBody = (url) => {
        const queryParams = extractUrlParams(url);
        const bodyParams = queryParams;

        return {
            bodyParams
        };
    };

    // Function to extract query parameters from the URL
    const extractUrlParams = (url) => {
        const urlObj = new URL(url);
        const params = new URLSearchParams(urlObj.search);
        const queryParams = {};
        for (const [key, value] of params) {
            queryParams[key] = value;
        }

        return queryParams;
    };
    const loadData = () => {
        const currentUrl = window.location.href;
        let { bodyParams } = getParamsAndBody(currentUrl);
        clientService
            .getMarketingStats({ bodyParams })
            .then((res) => {
                setRec({
                    ...res,
                    loading: false,
                });
                console.log("result is ", res);
            })
            .catch((err) => {
                console.log('err ', err);
            });
    };

    const appendParameterToUrl = (key, value) => {
        // Get the current URL
        const currentUrl = window.location.href;

        // Create a URL object
        const url = new URL(currentUrl);

        // Check if the parameter exists
        if (url.searchParams.has(key)) {
            // Update the existing parameter
            url.searchParams.set(key, value);
        } else {
            // Append the new parameter
            url.searchParams.append(key, value);
        }

        if ((key == 'callStatus' && value == '') || (key == 'source' && value == '') || (key == 'utmSource' && value == '')
            || (key == 'utmCampaign' && value == '')) {
            url.searchParams.delete(key);
        }
        // Update the browser's address bar
        window.history.pushState(null, '', url.toString());
        loadData();
    };
    return (
        <Row>
            <Filter filter={appendParameterToUrl} show={'marketing'} />
            <Row>
                <Col xl='4 xl-33' className={`chart_data_right `}>
                    <Card className='color_1'>
                        <div className='p-3 card-headers'>
                            Clients
                        </div>
                        <CardBody>
                            {rec &&
                                <Media className='align-items-center'>
                                    <Media body className='right-chart-content'>
                                        <div className='flex gap_20'>
                                            <div>
                                                <img src={Accounts} />
                                            </div>
                                            <div>
                                                <span className='f_24 semibold'>{rec.leadStats ? (parseInt(rec.leadStats.paidClients) + parseInt(rec.leadStats.organicClients)) : '0'}</span><br />
                                                <span className='new-boxs gray'>{'Total'}</span>
                                            </div>
                                        </div>
                                        <br /><br />
                                        <div className='flex justify-between'>
                                            <div>
                                                <span className='gray'>{'Paid'}</span><br />
                                                <span className='f_20 semibold'>{rec.leadStats ? rec.leadStats.paidClients : '0'}</span>
                                            </div>
                                            <div>
                                                <span className='gray'>{'Organic'}</span><br />
                                                <span className='f_20 semibold'>{rec.leadStats ? rec.leadStats.organicClients : '0'}</span>
                                            </div>
                                        </div>
                                    </Media>
                                </Media>
                            }
                        </CardBody>
                    </Card>
                </Col>
                <Col xl='4 xl-33' className={`chart_data_right `}>
                    <Card className='color_2'>
                        <div className='p-3 card-headers'>
                            Leads
                        </div>
                        <CardBody>
                            {rec && rec.leadStats &&
                                <Media className='align-items-center'>
                                    <Media body className='right-chart-content'>
                                        <div className='flex gap_20'>
                                            <div>
                                                <img src={Accounts} />
                                            </div>
                                            <div>
                                                <span className='f_24 semibold'>{rec.leadStats ? (parseInt(rec.leadStats.paidLeads) + parseInt(rec.leadStats.organicLeads)) : '0'}</span><br />
                                                <span className='new-boxs gray'>{'Total'}</span>
                                            </div>
                                        </div>
                                        <br /><br />
                                        <div className='flex justify-between'>
                                            <div>
                                                <span className='gray'>{'Paid'}</span><br />
                                                <span className='f_20 semibold'>{rec.leadStats ? rec.leadStats.paidLeads : '0'}</span>
                                            </div>
                                            <div>
                                                <span className='gray'>{'Organic'}</span><br />
                                                <span className='f_20 semibold'>{rec.leadStats ? rec.leadStats.organicLeads : '0'}</span>
                                            </div>
                                        </div>
                                    </Media>
                                </Media>
                            }
                        </CardBody>
                    </Card>
                </Col>
                <Col xl='4 xl-33' className={`chart_data_right `}>
                    <Card className='color_3'>
                        <div className='p-3 card-headers'>
                            Deposits
                        </div>
                        <CardBody>
                            {rec &&
                                <Media className='align-items-center'>
                                    <Media body className='right-chart-content'>
                                        <div className='flex gap_20'>
                                            <div>
                                                <img src={Deposit} alt='' />
                                            </div>
                                            <div>
                                                <span className='f_24 semibold'>{rec.deposits ? (parseInt(rec.deposits.paidDeposits) + parseInt(rec.deposits.organicDeposits)) + '$' : '0'}</span><br />
                                                <span className='new-boxs gray'>{'Total Deposits'}</span>
                                            </div>
                                        </div>
                                        <br /><br />
                                        <div className='flex justify-between'>
                                            <div>
                                                <span className='gray'>{'Paid'}</span><br />
                                                <span className='f_20 semibold'>{rec.deposits ? parseInt(rec.deposits.paidDeposits) + '$' : '0'}</span>
                                            </div>
                                            <div>
                                                <span className='gray'>{'Organic'}</span><br />
                                                <span className='f_20 semibold'>{rec.deposits ? parseInt(rec.deposits.organicDeposits) + '$' : '0'}</span>
                                            </div>
                                        </div>
                                    </Media>
                                </Media>
                            }
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Row>
    );
};

export default Index;