import React, { Fragment, useEffect, useState } from 'react';
import { Col, Row, Label, Table, Card, CardHeader, Form, Badge } from 'reactstrap';
import { ibService } from 'Services';
import TableLoader from 'CommonElements/Table/TableLoader';
import TableNodata from 'CommonElements/Table/TableNodata';
// import { useSelector } from "react-redux";
import WithoutFilterPagination from 'CommonElements/Table/WithoutFilterPagination';
import { useForm } from 'react-hook-form';
import Select from 'react-select';
import { FInput } from 'CommonElements/Form';
import { Btn, H4 } from "AbstractElements";
import moment from 'moment';
import { writeFile, utils } from 'xlsx';


const IbStatement = (props) => {
    const {
        customerId = '',
        activeTab, tabNumber
    } = props;
    const [recStatment, setRecStatment] = useState({});
    const [exportStatment, setExportStatment] = useState({});
    const [agreement, setAgreement] = useState({});
    const [selectedClient, setSelectedClient] = useState(null);
    const [dealWiseData, setDealWiseData] = useState(null);
    // State to manage the current page
    const [currentPage, setCurrentPage] = useState(1);
    let [limit, setLimit] = useState(10);
    const [statementDateRange, setStatementDateRange] = useState({});
    //   const permissions = useSelector(
    //     (state) => (state.login && state.login.profile && state.login.profile.Role && state.login.profile.Role.permissions) || {}
    //   );

    function exportToExcel(data) {
        const worksheet = utils.json_to_sheet(data);
        const workbook = utils.book_new();
        utils.book_append_sheet(workbook, worksheet, 'lead_sheet');
        return workbook;
    }

    const handleExport = () => {
        const dateFrom = moment().subtract(1, 'months').format('YYYY-MM-DD');
        const dateTo = moment().add(1, 'day').format('YYYY-MM-DD');
        setValue('dateFrom', dateFrom);
        setValue('dateTo', dateTo);
        const page = 1;
        // Fetch the data
        ibService.getExportStatement({ ibId: customerId, ...statementDateRange })
            .then((res) => {
                console.log('Statement fetched for export:', res);

                // Call the function to export the data to Excel
                exportToExcelAndWriteFile(res.data);
            })
            .catch((err) => {
                console.error('Error fetching statement for export:', err);
                setExportStatment({ loading: false });
            });

        // Function to export data to Excel and write the file
        function exportToExcelAndWriteFile(data) {
            // Call the function to export the data to Excel
            const workbook = exportToExcel(data);

            // Write the Excel file
            writeFile(workbook, 'lead_list.xlsx');
        }
    };

    const handleExport2 = () => {
        const dateFrom = moment().subtract(1, 'months').format('YYYY-MM-DD');
        const dateTo = moment().add(1, 'day').format('YYYY-MM-DD');
        setValue('dateFrom', dateFrom);
        setValue('dateTo', dateTo);
        const page = 1;
        // Fetch the data
        ibService.getExportStatement2({ ibId: customerId, ...statementDateRange })
            .then((res) => {
                console.log('Statement fetched for export:', res);

                // Call the function to export the data to Excel
                exportToExcelAndWriteFile(res);
            })
            .catch((err) => {
                console.error('Error fetching statement for export:', err);
                setExportStatment({ loading: false });
            });

        // Function to export data to Excel and write the file
        function exportToExcelAndWriteFile(data) {
            // Call the function to export the data to Excel
            const workbook = exportToExcel(data);

            // Write the Excel file
            writeFile(workbook, 'lead_list.xlsx');
        }
    };

    const options = [
        { value: '10', label: '10' },
        { value: '50', label: '50' },
        { value: '100', label: '100' },
        { value: '200', label: '200' },
        { value: '500', label: '500' },
        { value: '1000', label: '1000' }
    ]
    // Function to handle limit change
    const handleLimitChange = (selectedOption) => {
        const newLimit = selectedOption.value; // Use the selected option's value directly
        setLimit(newLimit); // Update the limit state
        console.log('hmmm', selectedOption.value);
        const page = 1;
        loadDataStatement(page, limit = newLimit)
    };
    const { register, handleSubmit, formState: { errors }, reset, setValue, control } = useForm();

    useEffect(() => {
        if (activeTab === tabNumber) {
            const dateFrom = moment().subtract(4, 'years').format('YYYY-MM-DD');
            const dateTo = moment().add(1, 'day').format('YYYY-MM-DD');
            setValue('dateFrom', dateFrom)
            setValue('dateTo', dateTo)
            setStatementDateRange({
                dateFrom,
                dateTo,
            })
            // loadData();
        }
    }, [activeTab, tabNumber]);

    const loadDataStatement = async (page = 1, limit) => {
        // console.log("date before sending ", statementDateRange);

        setCurrentPage(page);
        setRecStatment({
            loading: true,
        })
        // ibService.getAgreements(customerId).then((res) => {
        //     const data = res.data[0]?.members[0]?.AccountTypeValues;
        //     const accountType14 = data.find(item => item.accountTypeId === 14);
        //     const rebateValue = accountType14.rebate;

        //     if (activeTab === tabNumber) {
        //         setAgreement(rebateValue);
        //     }
        //     setRecStatment({
        //         ...res,
        //         loading: true,
        //     })
        // }).catch(err => {
        // });
        setDealWiseData(null);
        statementDateRange &&
            (ibService.getStatement({ ibId: customerId, page, ...statementDateRange, limit }).then((res) => {
                console.log('Statetment get ');
                setRecStatment({
                    ...res,
                    limit: limit,
                    loading: false,
                })
                console.log('statemnet is ', res);
            }).catch(err => {
                setRecStatment({
                    loading: false,
                })
            }))
    }

    const loadDealWiseSatement = async (page = 1) => {
        setDealWiseData({
            loading: true,
        });
        console.log('hosdlfkjk deal wise', selectedClient);
        ibService.getStatementDealWise({
            ibId: customerId,
            page,
            clientLogin: selectedClient?.clientLogin || selectedClient?.LOGIN,
            ...statementDateRange,
            platform: selectedClient.platform,
        }).then((res) => {
            console.log('Get Statement For LOGIN ', res);
            setDealWiseData({
                ...res,
                loading: false,
            });
        }).catch(err => {
            /*setDealWiseData({
                loading: false,
            });*/
        });
    }

    useEffect(() => {
        if (selectedClient && activeTab === tabNumber) {
            loadDealWiseSatement();
        }
    }, [selectedClient, activeTab, tabNumber]);

    const DealWiseTable = () => {
        return (<>
            {dealWiseData !== null && <Card>
                <CardHeader className='p-3'>
                    <Row>
                        <Col md={12} className="d-flex">
                            <H4 attrH4={{ className: "m-1" }}><Badge>Login - ({selectedClient?.clientLogin || selectedClient?.LOGIN}) </Badge></H4>
                            <H4 attrH4={{ className: "m-1" }}><Badge>Equity {dealWiseData?.clientEquity?.Equity ?? '---'} </Badge></H4>
                            <H4 attrH4={{ className: "m-1" }}><Badge>Balance {dealWiseData?.clientEquity?.Balance ?? '---'} </Badge></H4>
                            <H4 attrH4={{ className: "m-1" }}><Badge>MarginFree {dealWiseData?.clientEquity?.MarginFree ?? '---'} </Badge></H4>
                            <H4 attrH4={{ className: "m-1" }}><Badge>Credit {dealWiseData?.clientEquity?.Credit ?? '---'}</Badge></H4>
                        </Col>
                    </Row>
                </CardHeader>
                <div className="table-responsive">
                    <Table hover>
                        <thead>
                            <tr>
                                <th scope="col">{'Login'}</th>
                                <th scope="col">{'Deal Id'}</th>
                                {/*<th scope="col">{'Position Id'}</th>*/}
                                <th scope="col">{'Lots'}</th>
                                <th scope="col">{'Rebate'}</th>
                                <th scope="col">{'Symbol'}</th>
                                <th scope="col">{'Profit'}</th>
                            </tr>
                        </thead>
                        {dealWiseData.loading && <TableLoader />}
                        {!dealWiseData.loading && dealWiseData.data && dealWiseData.data.length === 0 && <TableNodata title="client deals" />}

                        <tbody>
                            {
                                dealWiseData.data && dealWiseData.data.map((item, id) =>
                                    <tr key={id}>
                                        <td >{item['SourceDeal.login'] || item.LOGIN}</td>
                                        <td >{item['SourceDeal.dealId'] || item.TICKET}</td>
                                        <td >{item['SourceDeal.volumeClosed'] / 10000 || item.VOLUME / 100}</td>
                                        <td >{item.profit || ((item.VOLUME / 100) * agreement) || 0}</td>
                                        <td >{item['SourceDeal.symbol'] || item.SYMBOL || '---'}</td>
                                        <td >{item['SourceDeal.profit'] || item.PROFIT || '---'}</td>
                                    </tr>
                                )
                            }
                        </tbody>
                    </Table>
                    <WithoutFilterPagination data={dealWiseData} loadData={loadDealWiseSatement} />

                </div>
            </Card>}
        </>)
    }

    const modalSubmit = formValues => {
        if (formValues !== '') {
            console.log('Date is ', formValues);
            setStatementDateRange(formValues);
            //loadDataStatement(1);
        }
    };


    useEffect(() => {
        if (activeTab === tabNumber) {
            console.log("hmmm date range set ");
            loadDataStatement(1, limit);
        }
    }, [statementDateRange, activeTab, tabNumber])
    return (
        <Fragment>
            <Row>
                <Col md={6}>
                    <Form className="needs-validation" noValidate=""
                        onSubmit={handleSubmit(modalSubmit)}
                    >
                        <Row>
                            <Col md='3 mb-3' >
                                <FInput
                                    errors={errors}
                                    label='Date From'
                                    name='dateFrom'
                                    register={register}
                                    placeholder='Select date from'
                                    type='date'
                                    validation={{ required: true }}
                                />
                            </Col>
                            <Col md='3 mb-3' >
                                <FInput
                                    errors={errors}
                                    label='Date To'
                                    name='dateTo'
                                    register={register}
                                    placeholder='Select date to'
                                    type='date'
                                    validation={{ required: true }}
                                />
                            </Col>
                            <Col md={3} className="mt-4 pt-2">
                                <Btn disabled={recStatment.loading} attrBtn={{ color: 'primary' }} >{'Update'}</Btn>
                            </Col>

                        </Row>

                        {/* <Btn disabled={submitState.loading}  attrBtn={{ color: 'primary' }} >{'Update'}</Btn> */}
                    </Form>
                </Col>
                <Col md={2}>
                    <Label>Select</Label>
                    <Select
                        label='Select'
                        defaultValue={options.find(option => option.value === limit.toString())}
                        onChange={handleLimitChange}
                        options={options}
                    />
                </Col>
                <Col md='2' className="mt-4 pt-2">
                    <Btn
                        attrBtn={{
                            color: 'success',
                            onClick: handleExport,
                        }}
                    >
                        Export
                    </Btn>
                </Col>
                <Col md='2' className="mt-4 pt-2">
                    <Btn
                        attrBtn={{
                            color: 'success',
                            onClick: handleExport2,
                        }}
                    >
                        Export 2
                    </Btn>
                </Col>
            </Row>
            <Card>
                <CardHeader className='p-3'>
                    <Row>
                        <Col md={6} className="d-flex">
                            IB Statement
                        </Col>
                    </Row>
                </CardHeader>

                <div className="table-responsive">
                    <Table hover>
                        <thead>
                            <tr>
                                <th scope="col">{'IB Account'}</th>
                                <th scope="col">{'Client Account'}</th>
                                <th scope="col">{'Commision'}</th>
                                <th scope="col">{'Lots'}</th>
                                <th scope="col">{'Rebate'}</th>
                            </tr>
                        </thead>
                        {recStatment.loading && <TableLoader />}
                        {!recStatment.loading && recStatment.data && recStatment.data.length === 0 && <TableNodata title="ib statement" />}

                        <tbody>
                            {
                                recStatment.data && recStatment.data.map((item, id) =>
                                    <tr key={id}>
                                        <td >{item.login}</td>
                                        <td >
                                            <span
                                                style={{ textDecoration: 'underline', fontWeight: 'bold', cursor: 'pointer' }}
                                                onClick={() => { setSelectedClient(item) }}
                                            >
                                                {item.clientLogin}
                                            </span>
                                        </td>
                                        <td>{item.commission}</td>
                                        <td >{item.volumes && item.volumes / 10000 || item.volume && item.volume / 100}</td>
                                        <td className={item.rebate}>{item.rebate}</td>

                                    </tr>
                                )
                            }
                        </tbody>
                    </Table>
                    {recStatment.data && recStatment.data.length > 1 && recStatment.totalPages > 1 &&
                        <WithoutFilterPagination data={recStatment} loadData={loadDataStatement} limit={limit} />
                    }
                </div>
            </Card>
            <DealWiseTable />

        </Fragment>
    );
};
export default IbStatement;