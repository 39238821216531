import api from './api';

export const BonusGroup = () => {
    return api.get('/v1/crm/BonusGroup/');
};

export const createBonusGroup = (data) => {
  return api.post('/v1/crm/BonusGroup/', data);
};

export const deleteBonusGroup = (id) => {
  return api.delete(`/v1/crm/BonusGroup/${id}`);
};

export const updateBonusGroup = (id, updatedData) => {
  return api.put(`/v1/crm/BonusGroup/${id}`, updatedData);
};

