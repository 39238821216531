import React from 'react';
import { Card, CardBody, CardHeader, CardTitle, Col, Media } from 'reactstrap';
import { H4 } from '../../../../../AbstractElements';
import Accounts from '../../../../../assets/images/avtar/accounts.svg';
import FirstTime from '../../../../../assets/images/avtar/ftDeposit.svg';
import Deposit from '../../../../../assets/images/avtar/deposits.svg';
import { formatMoney } from 'Components/Common/Utility';
import { useSelector } from "react-redux";

const SmallApexChart = ({ accounts = {}, depositsFtd = {}, deposits = {}, depositsSales = {} }) => {

  // Calculate totals for each column
  const totals = depositsSales.length > 0 && depositsSales?.reduce((acc, item) => {
    acc.totalFtDepositOfMonth += parseInt(item['Customer.totalFtDepositOfMonth']) || 0;
    acc.totalFtDepositLastMonth += parseInt(item['Customer.totalFtDepositLastMonth']) || 0;
    acc.currentMonth += (item.currentMonth) || 0;
    acc.lastMonth += (item.lastMonth) || 0;
    acc.today += parseInt(item.today) || 0;
    return acc;
  }, { totalFtDepositOfMonth: 0, totalFtDepositLastMonth: 0, currentMonth: 0, lastMonth: 0, today: 0 });

  return (
    <>
      <Col xl='4 xl-33' className={`chart_data_right `}>
        <Card className='color_1'>
          <div className='p-3 card-headers'>
            Accounts
          </div>
          <CardBody>
            {accounts &&
              <Media className='align-items-center'>
                <Media body className='right-chart-content'>
                  <div className='flex gap_20'>
                    <div>
                      <img src={Accounts} />
                    </div>
                    <div>
                      <span className='f_24 semibold'>{accounts.currentMonth ? Number(accounts.currentMonth) : '0'}</span><br />
                      <span className='new-boxs gray'>{'Month to Date'}</span>
                    </div>
                  </div>
                  <br /><br />
                  <div className='flex justify-between'>
                    <div>
                      <span className='gray'>{'Last Month'}</span><br />
                      <span className='f_20 semibold'>{accounts.lastMonth ? Number(accounts.lastMonth) : '0'}</span>
                    </div>
                    <div>
                      <span className='gray'>{'Today'}</span><br />
                      <span className='f_20 semibold'>{accounts.today ? Number(accounts.today) : '0'}</span>
                    </div>
                  </div>
                </Media>
              </Media>
            }
          </CardBody>
        </Card>
      </Col>
      <Col xl='4 xl-33' className={`chart_data_right `}>
        <Card className='color_2'>
          <div className='p-3 card-headers'>
            First Time Deposits
          </div>
          <CardBody>
            {depositsSales && depositsSales.length > 0 &&
              <Media className='align-items-center'>
                <Media body className='right-chart-content'>
                  <div className='flex gap_20'>
                    <div>
                      <img src={FirstTime} alt='firstTime' />
                    </div>
                    <div>
                      <span className='f_24 semibold'>{ totals.totalFtDepositOfMonth ? formatMoney(parseInt(totals.totalFtDepositOfMonth)) + '$' : '0'}</span><br />
                      <span className='new-boxs gray'>{'Month to Date'}</span>
                    </div>
                  </div>
                  <br /><br />
                  <div className='flex justify-between'>
                    <div>
                      <span className='gray'>{'Last Month'}</span><br />
                      <span className='f_20 semibold'>{totals.totalFtDepositLastMonth ? formatMoney(parseInt(totals.totalFtDepositLastMonth)) + '$' : '0'}</span>
                    </div>
                    <div>
                      <span className='gray'>{'Today'}</span><br />
                      <span className='f_20 semibold'>{depositsFtd.today.deposits ? formatMoney(parseInt(depositsFtd.today.deposits)) + '$' : '0'}</span>
                    </div>
                  </div>
                </Media>
              </Media>
            }
          </CardBody>
        </Card>
      </Col>
      <Col xl='4 xl-33' className={`chart_data_right `}>
        <Card className='color_3'>
          <div className='p-3 card-headers'>
            Deposits
          </div>
          <CardBody>
            {accounts &&
              <Media className='align-items-center'>
                <Media body className='right-chart-content'>
                  <div className='flex gap_20'>
                    <div>
                      <img src={Deposit} alt='deposit' />
                    </div>
                    <div>
                      <span className='f_24 semibold'>{totals.currentMonth ? formatMoney(parseInt(totals.currentMonth)) + '$' : '0'}</span><br />
                      <span className='new-boxs gray'>{'Month to Date'}</span>
                    </div>
                  </div>
                  <br /><br />
                  <div className='flex justify-between'>
                    <div>
                      <span className='gray'>{'Last Month'}</span><br />
                      <span className='f_20 semibold'>{totals.lastMonth ? formatMoney(parseInt(totals.lastMonth)) + '$' : '0'}</span>
                    </div>
                    <div>
                      <span className='gray'>{'Today'}</span><br />
                      <span className='f_20 semibold'>{deposits.today ? formatMoney(parseInt(deposits.today)) + '$' : '0'}</span>
                    </div>
                  </div>
                </Media>
              </Media>
            }
          </CardBody>
        </Card>
      </Col>
    </>
  );
};

export default SmallApexChart;
